<template>
  <div class="bus-city content-padding">
    <h2>交通卡及城市列表</h2>
    <table>
      <tr>
        <th>卡名称</th>
        <th>支持城市</th>
      </tr>
      <tr>
        <td>京津冀互联互通卡</td>
        <td>305个城市（详情见表2）</td>
      </tr>
      <tr>
        <td>上海公共交通卡</td>
        <td>上海</td>
      </tr>
      <tr>
        <td>岭南通（交通联合）</td>
        <td>广州 佛山 江门 肇庆 汕尾 惠州 茂名 珠海 揭阳 韶关 河源 云浮 中山 潮州 清远 湛江 汕头 阳江 梅州 深圳 东莞</td>
      </tr>
      <tr>
        <td>合肥通（交通联合）</td>
        <td>合肥</td>
      </tr>
      <tr>
        <td>武汉通</td>
        <td>武汉 仙桃 应城 安陆 大冶 孝感 汉川</td>
      </tr>
      <tr>
        <td><span>江苏交通一卡通·</span><span>苏州</span></td>
        <td>南京 无锡 徐州 常州 苏州 南通 淮安 盐城 扬州 镇江 泰州 宿迁 连云港</td>
      </tr>
      <tr>
        <td><span>江苏交通一卡通·</span><span>南通</span></td>
        <td>南通 盐城 泰州</td>
      </tr>
      <tr>
        <td>江苏交通一卡通·无锡</td>
        <td>无锡</td>
      </tr>
      <tr>
        <td><span>江苏交通一卡通·</span><span>常州</span></td>
        <td>常州</td>
      </tr>
      <tr>
        <td>潇湘卡</td>
        <td>长沙</td>
      </tr>
      <tr>
        <td>绿城通</td>
        <td>郑州</td>
      </tr>
      <tr>
        <td>琴岛通</td>
        <td>青岛</td>
      </tr>
      <tr>
        <td>吉林通</td>
        <td>长春 吉林 四平 通化 延边朝鲜族自治区 长白山保护开发区</td>
      </tr>
      <tr>
        <td>盛京通</td>
        <td>沈阳</td>
      </tr>
      <tr>
        <td>西藏交通一卡通</td>
        <td>拉萨</td>
      </tr>
      <tr>
        <td>车城通（交通联合）</td>
        <td>十堰</td>
      </tr>
      <tr>
        <td>驼城通</td>
        <td>榆林</td>
      </tr>
      <tr>
        <td>台州公共交通卡</td>
        <td>台州 温岭 临海</td>
      </tr>
      <tr>
        <td>哈尔滨城市通</td>
        <td>哈尔滨</td>
      </tr>
      <tr>
        <td>石家庄一卡通</td>
        <td>石家庄</td>
      </tr>
      <tr>
        <td>厦门e通卡</td>
        <td>厦门</td>
      </tr>
      <tr>
        <td>大连明珠卡</td>
        <td>大连</td>
      </tr>
      <tr>
        <td>长安通互联互通卡</td>
        <td>西安 咸阳</td>
      </tr>
      <tr>
        <td>河北交通一卡通</td>
        <td>石家庄</td>
      </tr>
      <tr>
        <td>绍兴交通一卡通</td>
        <td>绍兴</td>
      </tr>
      <tr>
        <td>赣州通</td>
        <td>赣州</td>
      </tr>
    </table>
    <h3>京津冀互联互通卡支持城市列表（表2）</h3>
    <table>
      <tr>
        <td>北京</td>
        <td>北京</td>
      </tr>
      <tr>
        <td>上海</td>
        <td>上海</td>
      </tr>
      <tr>
        <td>天津</td>
        <td>天津</td>
      </tr>
      <tr>
        <td>河北</td>
        <td>石家庄 保定 唐山 廊坊 承德 沧州 邢台 邯郸 衡水 秦皇岛 张家口</td>
      </tr>
      <tr>
        <td>广东</td>
        <td>深圳 广州 珠海 东莞（公交） 汕头 佛山 阳江 中山 肇庆 韶关 河源 惠州 茂名 汕尾 江门 清远 梅州 揭阳 云浮 潮州 湛江</td>
      </tr>
      <tr>
        <td>江苏</td>
        <td>南京 无锡 徐州 常州 苏州 南通 昆山 淮安 盐城 扬州 镇江 泰州 宿迁 连云港</td>
      </tr>
      <tr>
        <td>浙江</td>
        <td>杭州 绍兴 台州 湖州 温州 嘉兴 宁波 衢州 金华 丽水</td>
      </tr>
      <tr>
        <td>山东</td>
        <td>济南 青岛 烟台 临沂 威海 菏泽 滨州 聊城 济宁 德州 东营 枣庄 潍坊 淄博（部分公交） 泰安（部分公交） 日照（部分公交）</td>
      </tr>
      <tr>
        <td>河南</td>
        <td>郑州 洛阳 开封 南阳 安阳 周口 焦作 濮阳 商丘 许昌 新乡 鹤壁 漯河 信阳 济源 平顶山 驻马店 三门峡</td>
      </tr>
      <tr>
        <td>湖北</td>
        <td>武汉（公交） 鄂州 荆门 十堰 宜昌 襄阳 随州 潜江 黄冈 黄石 咸宁 孝感</td>
      </tr>
      <tr>
        <td>福建</td>
        <td>福州 厦门 莆田 泉州 漳州 三明 宁德 龙岩 南平 平潭</td>
      </tr>
      <tr>
        <td>四川</td>
        <td>成都（部分公交） 泸州 南充 乐山 宜宾 雅安 达州 内江 自贡 西昌 广元 遂宁 德阳 绵阳 眉山 巴中 广安 阆中 攀枝花 阿坝藏族羌族自治州 甘孜藏族自治州 凉山彝族自治州 资阳</td>
      </tr>
      <tr>
        <td>辽宁</td>
        <td>沈阳 大连 鞍山 抚顺 朝阳 营口 盘锦 丹东 锦州 铁岭 葫芦岛 辽阳 阜新 本溪</td>
      </tr>
      <tr>
        <td>陕西</td>
        <td>西安 宝鸡 咸阳 汉中 安康 渭南 延安 铜川 韩城 榆林 商洛 杨凌</td>
      </tr>
      <tr>
        <td>湖南</td>
        <td>长沙 岳阳 湘潭 怀化 邵阳 衡阳 郴州 株洲 常德 益阳 娄底 永州 张家界 湘西土家苗族自治州</td>
      </tr>
      <tr>
        <td>安徽</td>
        <td>合肥 黄山 蚌埠 淮南 淮北 滁州 宣城 池州 安庆 宿州 铜陵 六安 芜湖 亳州 阜阳 马鞍山</td>
      </tr>
      <tr>
        <td>广西壮族自治区</td>
        <td>南宁 桂林 北海 钦州 贵港 玉林 来宾 柳州 河池 崇左 贺州 百色 梧州 防城港 </td>
      </tr>
      <tr>
        <td>山西</td>
        <td>太原 晋中 运城 吕梁 忻州 长治 临汾 大同 晋城 朔州 阳泉</td>
      </tr>
      <tr>
        <td>云南</td>
        <td>昆明 楚雄 玉溪 保山 曲靖 红河哈尼族彝族自治州 迪庆藏族自治州 昭通 文山壮族苗族自治州 丽江 西双版纳傣族自治州 普洱 怒江傈僳族自治州 临沧 大理白族自治州（部分公交） 德宏傣族景颇族自治州（部分公交）</td>
      </tr>
      <tr>
        <td>江西</td>
        <td>南昌 赣州 九江 新余 抚州 萍乡 上饶 吉安 鹰潭 景德镇 宜春</td>
      </tr>
      <tr>
        <td>黑龙江</td>
        <td>哈尔滨 齐齐哈尔 七台河 伊春 大兴安岭 绥化（部分公交） </td>
      </tr>
      <tr>
        <td>贵州</td>
        <td>贵阳 黔南布依族自治州 毕节 遵义 铜仁 六盘水 黔东南苗族侗族自治州 黔西南布依族苗族自治州 安顺（部分公交）</td>
      </tr>
      <tr>
        <td>吉林</td>
        <td>长春 吉林 四平 通化 白山 白城 松原 辽源 延边朝鲜族自治州 长白山管委会</td>
      </tr>
      <tr>
        <td>内蒙古自治区</td>
        <td>呼和浩特 包头 乌海 鄂尔多斯 通辽（部分公交） 兴安盟（部分公交） 呼伦贝尔（部分公交） 乌兰察布（部分公交）</td>
      </tr>
      <tr>
        <td>甘肃</td>
        <td>兰州 金昌 酒泉 武威 张掖 平凉 陇南 嘉峪关 定西 天水（部分公交） 白银（部分公交） 庆阳（部分公交）</td>
      </tr>
      <tr>
        <td>青海</td>
        <td>西宁 海东 海西蒙古族藏族自治州 玉树藏族自治州</td>
      </tr>
      <tr>
        <td>宁夏回族自治区</td>
        <td>银川 固原 吴忠 石嘴山</td>
      </tr>
      <tr>
        <td>海南</td>
        <td>三亚 文昌 海口 儋州</td>
      </tr>
      <tr>
        <td>西藏自治区</td>
        <td>拉萨</td>
      </tr>
      <tr>
        <td>新疆维吾尔自治区</td>
        <td>乌鲁木齐 哈密 伊犁哈萨克自治州 新疆生产建设兵团（部分公交）</td>
      </tr>
    </table>
    <div class="tip">
      <p>1. 表中所列城市使用范围，如无特殊说明，均指市区公交线路或城市轨道交通线路（不含定制公交等特殊线路）；</p>
      <p>2. 交通联合卡在乘车刷卡时请确认刷卡终端的“交通联合”标识；</p>
      <p>3. 交通联合卡的使用范围及乘车优惠以当地政府及运营企业发布为准；</p>
      <p>4. 交通联合卡不支持透支，卡内余额不得低于您乘车线路的单程最高票价（部分城市的交通卡透支规则以当地政府及运营企业发布为准）；</p>
      <p>5. 交通联合卡在使用过程中若产生不完整交易记录（如地铁进站后未刷卡出站），须在刷卡交易城市的地铁人工服务台补齐交易记录后方可继续使用。存在不完整交易的交通联合卡在全国地铁线路均无法正常进站乘车。</p>
      <p>陆续支持更多城市，请关注官方信息</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bus-city3',
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.bus-city {
  h2 {
    font-size: 20px;
    margin-left: 0.33333rem;
  }

  h3 {
    font-size: 18px;
    margin-left: 0.33333rem;
  }

  .tip {
    font-size: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    tr {
      td, th {
        font-size: 12px;
        border: 1px solid #ddd;
        line-height: 1.6;

        &:nth-child(1) {
          width: 40%;
          padding: 20px;
          border-left: none;

          span {
            display: block;
            white-space: nowrap;
          }
        }

        &:nth-child(2) {
          padding: 20px;
          border-right:none;
        }
      }
    }
  }
}
</style>
