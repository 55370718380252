<template>
  <div class="product">
    <van-icon class="back" name="arrow-left" @click="goprev"/>
    <router-link to="/"><img class="logo" src="https://site-cdn.huami.com/files/amazfit/mobile/assets/logo.png"></router-link>
    <div class="content-padding">
      <van-swipe indicator-color="white" :class="[video?'videoClass':'']" @change="videoPlay">
        <van-swipe-item v-if="video"><video @click="videoControl" ref="video" :poster="video.poster" :src="video.url" controls preload ></video></van-swipe-item>
        <van-swipe-item v-for="item in swiperData[activeSpec]" :key="item">
          <img :src="item" alt="">
        </van-swipe-item>
      </van-swipe>
      <div class="panel-part">
        <div class="panel">
          <h1>{{ productTitle }}</h1>
          <p class="slogan" v-html="productSlogan"></p>
          <div class="price" v-if="productPrice">
            <span class="currency">¥</span>
            {{ productPrice }}
          </div>
        </div>

        <div class="panel">
          <h2>规格</h2>

          <ul class="colors">
            <li class="item" :class="{current: activeSpec==index}" v-for="(color, index) in colors" :key="index" @click="activeSpec=index">{{ color }}</li>
          </ul>
        </div>

        <div class="panel" v-if="relativedProducts && relativedProducts.length>0">
          <h2>配件</h2>
          <div class="relatived-products">
            <div class="item" v-for="(item, index) in relativedProducts" :key="index" @click="$router.push(item.link)">
              <img :src="item.image" alt="">
              <div class="name" v-html="item.name"></div>
              <div class="price">
                <span class="currency">¥</span>
                {{ item.price }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-tabs v-model="activeTab" class="description" sticky lazy-render>
      <keep-alive>
        <van-tab title="商品详情" style="min-height:10rem">
            <div v-for="(image, i) in detailSpecImages" :key="i">
              <router-link v-if="image.url" :to="image.url"><van-image :src="image.img" alt="" lazy-load /></router-link>
              <van-image v-else :src="image" alt="" lazy-load />
            </div>
            <van-swipe class="last-swiper" :autoplay="5000" indicator-color="white" v-if="lastSwiper && lastSwiperData.length > 0">
                <van-swipe-item v-for="item in lastSwiperData" :key="item">
                  <img :src="item" alt="">
                </van-swipe-item>
            </van-swipe>
            <van-image :src="image" alt="" lazy-load v-for="(image, index) in paramSpecImages" :key="'params-' +  index"/>
        </van-tab>
      </keep-alive>
      <keep-alive>
        <van-tab title="商品参数" style="min-height:10rem">
            <van-image :src="image" alt="" lazy-load v-for="(image, index) in paramSpecImages" :key="index"/>
        </van-tab>
      </keep-alive>
      <van-tab title="城市公交" v-if="isBusCity">
        <bus-city></bus-city>
      </van-tab>
      <van-tab title="城市公交" v-if="isBusCity1">
        <bus-city1></bus-city1>
      </van-tab>
      <van-tab title="城市公交" v-if="isBusCity2">
        <bus-city2></bus-city2>
      </van-tab>
      <van-tab title="城市公交" v-if="isBusCity3">
        <bus-city3></bus-city3>
      </van-tab>
    </van-tabs>

    <div class="bottom-bar">
      <div class="container">
        <router-link to="/" class="home"><img src="https://site-cdn.huami.com/files/amazfit/mobile/assets/home.png">首页</router-link>

        <a v-if="link" :href="link" class="buy-now">立即购买</a>
      </div>
    </div>
  </div>
</template>

<script>
// import { CDN_URL } from '../config'
import BusCity from '../components/BusCity'
import BusCity1 from '../components/BusCity1'
import BusCity2 from '../components/BusCity2'
import BusCity3 from '../components/BusCity3'

export default {
  name: 'product',
  components: {
    BusCity,
    BusCity1,
    BusCity2,
    BusCity3
  },
  props: {
    title: {
      type: [String, Array],
      required: true
    },
    swiperData: {
      type: Array
    },
    slogan: {
      type: [String, Array]
    },
    price: {
      type: [Number, String, Array]
    },
    colors: {
      type: Array
    },
    relativedProducts: {
      type: Array
    },
    detailImages: {
      type: Array
    },
    paramImages: {
      type: Array
    },
    buyLink: {
      type: [String, Array]
    },
    isSwitchDetail: {
      type: Boolean,
      default: false
    },
    isSwitchSlogan: {
      type: Boolean,
      default: false
    },
    isSwitchPrice: {
      type: Boolean,
      default: false
    },
    isSwitchTitle: {
      type: Boolean,
      default: false
    },
    isBusCity: {
      type: Boolean,
      default: false
    },
    isBusCity1: {
      type: Boolean,
      default: false
    },
    isBusCity2: {
      type: Boolean,
      default: false
    },
    isBusCity3: {
      type: Boolean,
      default: false
    },
    isLinkArray: {
      type: Boolean,
      default: false
    },
    video: {
      type: Object
    },
    lastSwiper: {
      type: Boolean,
      default: false
    },
    lastSwiperData: {
      type: Array
    }
  },
  created () {
    console.log(this.$router)
  },
  data () {
    let activeSpec = this.$route.query.spec ? this.$route.query.spec : 0

    return {
      activeTab: 0,
      activeSpec: activeSpec,
      detailSpecImages: this.isSwitchDetail ? this.detailImages[activeSpec] : this.detailImages,
      paramSpecImages: this.isSwitchDetail ? this.paramImages[activeSpec] : this.paramImages,
      productPrice: this.isSwitchPrice ? this.price[activeSpec] : this.price,
      productSlogan: this.isSwitchSlogan ? this.slogan[activeSpec] : this.slogan,
      productTitle: this.isSwitchTitle ? this.title[activeSpec] : this.title,
      link: this.isLinkArray ? this.buyLink[activeSpec] : this.buyLink,
      isPlay: true
    }
  },
  watch: {
    activeSpec (val) {
      console.log(val)
      if (this.isSwitchDetail) {
        this.detailSpecImages = this.detailImages[val]
        this.paramSpecImages = this.paramImages[val]
      }

      if (this.isSwitchSlogan) {
        this.productSlogan = this.slogan[val]
      }

      if (this.isSwitchPrice) {
        this.productPrice = this.price[val]
      }

      if (this.isSwitchTitle) {
        this.productTitle = this.title[val]
      }
      if (this.isLinkArray) {
        this.link = this.buyLink[val]
      }
    }
  },
  methods: {
    goprev () {
      let pathArrStr = sessionStorage.getItem('pathArrStr')
      let pathArr = pathArrStr.split(',')
      if (pathArr.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push({ path: '/' })
      }
      // if (routerLen === 1) {
      //   this.$router.push({ path: '/' })
      // } else {
      //   this.$router.go(-1)
      // }
      // if (window.history.length <= 1) {
      //   this.$router.push({ path: '/' })
      // } else {
      //   this.$router.go(-1)
      // }
      // setTimeout(() => {
      //   this.$router.push({ path: '/' })
      // })
    },
    videoPlay (e) {
      if (this.videoUrl) {
        if (e === 0) {
          this.$refs.video.play()
        } else {
          this.$refs.video.pause()
        }
      }
    },
    videoControl () {
      if (this.isPlay) {
        this.$refs.video.play()
      } else {
        this.$refs.video.pause()
      }
      this.isPlay = !this.isPlay
    }
  }
}
</script>

<style lang="scss">
.last-swiper{
  margin:0 !important;
  height: 6.5rem !important;
  background-color: #ffffff !important;
  img{
    max-width: 94% !important;
    text-align: center;
    margin-left: 3%;
  }
  .van-swipe__indicators{
    bottom:1.42rem;
  }
  .van-swipe__indicator{
    width:0.16rem;
    height: 0.16rem;
    border-radius: 0.08rem;
    background: #333333;
    margin: 0.08rem 0.21333rem;
  }
  .van-swipe__indicator--active{
    margin: 0.08rem 0.21333rem;
    border: none;
  }
}
.product {
  background-color: #F3F3F3;
  padding-bottom: 70px;
  .content-padding {
    padding: .33333rem 0;
  }
  .panel-part {
    padding: 0 .33333rem;
  }
  .back {
    position: absolute;
    font-size: 18px;
    top: 12.5px;
    left: 12.5px;
  }

  .logo {
    position: absolute;
    width: 124px;
    left: 125px;
    top: 10px;
  }

  .van-swipe{
    margin-top: 67.5px;
    overflow: show;
    height: 270px;
  }

  .van-swipe-item {
    text-align: center;
    video {
      width: 100%;
      height: 60vw;
    }
    img {
      max-width: 225px;
      max-height: 225px;
    }
  }
  .videoClass .van-swipe__indicators {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .videoClass .van-swipe__indicators i:first-child{
    width: 0.3rem!important;
    height: 0.3rem!important;
    margin: 0 0.13333rem!important;
    border: 0.02667rem solid #000!important;
    border-radius: 50%!important;
    background:#fff url('https://site-cdn.huami.com/files/amazfit/mobile/images/icon/play.png') center center no-repeat!important;
    opacity: 1!important;
  }
  .panel {
    background-color: #FFF;
    margin: 10px 0 0;
    padding: 25px 20px;
    background:rgba(255,255,255,1);
    border:1px solid rgba(0, 0, 0, 0.07);
    border-radius:5px;

    h1 {
      margin-top: 0;
      font-size: 15px;
      line-height: 100%;
      color: #333;
    }

    h2 {
      height:15px;
      color: #333;
      border-left: 2px solid #333;
      line-height: 100%;
      padding-left: 7px;
      margin-left: 5px;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .slogan {
      font-size: 10px;
      color: #666;
    }

    .price {
      color: #D42D3B;
      font-size:24px;

      .currency {
        font-size:10px;
      }
    }
  }

  .colors {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 60px;
      height: 22px;
      border:1px solid rgba(51,51,51,1);
      border-radius:11px;
      margin-right: 15px;
      margin-top: 14.5px;
      text-align: center;
      font-size: 10px;
      line-height: 22px;

      &.current {
        color: #D42D3B;
        border-color: #D42D3B;
      }
    }
  }

  .relatived-products {
    display: flex;
    padding-top: 10px;

    .item {
      flex-basis: 45%;
      text-align: center;

      img {
        height: 75px;
      }

      .name {
        font-size: 10px;
        color: #333;
        line-height: 110%;
      }

      .price {
        margin-top: 15px;
        font-size: 15px;
      }
    }
  }

  .description {
    .van-image {
      // 解决移动端图片直接的缝隙问题，可参考：https://segmentfault.com/q/1010000011417835
      margin-top: -1px;
      display: block;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  .bottom-bar {
    position: fixed;
    width: 100%;
    background-color: #FFF;
    bottom: 10px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius:25px;
    z-index: 100;

    .container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .home {
      display: block;
      position: absolute;
      left: 30.5px;
      top: 10px;
      width: 26px;
      color: #333;
      font-size: 10px;
      text-align: center;

      img {
        display: inline-block;
        width: 18.5px;
      }
    }

    .buy-now {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 30px;
      padding: 0 26.5px;
      background-color: #D42D3B;
      border-radius:15px;
      font-size:12px;
      line-height: 30px;
      color: #FFF;
    }
  }
}
</style>
